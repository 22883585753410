

.base-measurement {
  width: 90px;
  position: absolute;
  padding: 1px;
  border: 1px solid #dadada;
  border-radius: 10px;
  font-size: 14px;
  z-index: 1000;
  text-align: center;
}

.txl-container-grid-items {
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  grid-auto-rows: minmax(196px, auto);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
}

.txl-container-grid-item {
  grid-column: auto / span 2;
}

.breadcrumb-item {
  font-size:16px;
}

.react-month-picker .calendar-container {
  left:0 !important;
  right:inherit !important;
}